<template>
    <div>
        <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
        <div
            class="relative z-10 lg:hidden"
            role="dialog"
            aria-modal="true"
        >
            <Transition>
                <div
                    v-if="mobileMenuShow"
                    class="fixed inset-0 bg-gray-900/80"
                />
            </Transition>

            <div
                v-if="mobileMenuShow"
                class="fixed inset-0 flex"
            >
                <div
                    class="relative mr-16 flex w-full transition ease-in-out duration-300 transform max-w-xs flex-1"
                    :class="{
                        'translate-x-0': mobileMenuShow,
                        '-translate-x-full': !mobileMenuShow
                    }"
                >
                    <Transition>
                        <div
                            v-if="mobileMenuShow"
                            class="absolute left-full top-0 flex w-16 justify-center pt-5"
                            @click="mobileMenuShow = false"
                        >
                            <button
                                type="button"
                                class="-m-2.5 p-2.5"
                            >
                                <span class="sr-only">Close sidebar</span>
                                <svg
                                    class="h-6 w-6 text-white"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>
                    </Transition>

                    <!-- Sidebar component, swap this element with another sidebar if you like -->
                    <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-secondary-600 px-6 pb-4">
                        <div class="flex h-16 shrink-0 items-center">
                            <img
                                class="h-8 w-auto"
                                src="/img/logo-beige.png"
                                alt="Kray Logo"
                            >
                        </div>
                        <nav
                            class="flex flex-1 flex-col"
                            aria-label="Sidebar"
                        >
                            <ul
                                role="list"
                                class="flex flex-1 flex-col gap-y-7"
                            >
                                <li>
                                    <NuxtLink
                                        to="/"
                                        class="text-secondary-200 hover:text-white hover:bg-secondary-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                        active-class="bg-secondary-700 text-white"
                                    >
                                        <HomeIconOutline class="h-6 w-6 shrink-0 text-white" />
                                        Dashboard
                                    </NuxtLink>
                                </li>
                                <li v-if="isAdmin || isManager">
                                    <NuxtLink
                                        to="/contact"
                                        class="text-secondary-200 hover:text-white hover:bg-secondary-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                        active-class="bg-secondary-700 text-white"
                                    >
                                        <IdentificationIconOutline class="h-6 w-6 shrink-0 text-white" />
                                        Contacts
                                    </NuxtLink>
                                </li>
                                <li v-if="isAdmin || isManager">
                                    <NuxtLink
                                        to="/client"
                                        class="text-secondary-200 hover:text-white hover:bg-secondary-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                        active-class="bg-secondary-700 text-white"
                                    >
                                        <UserGroupIconOutline class="h-6 w-6 shrink-0 text-white" />
                                        Clients
                                    </NuxtLink>
                                </li>
                                <li v-if="isAdmin || isOps || isManager">
                                    <NuxtLink
                                        to="/prospection"
                                        class="text-secondary-200 hover:text-white hover:bg-secondary-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                        active-class="bg-secondary-700 text-white"
                                    >
                                        <BeakerIconOutline class="h-6 w-6 shrink-0 text-white" />
                                        Prospection
                                    </NuxtLink>
                                </li>
                                <li v-if="isAdmin || isOps || isManager">
                                    <NuxtLink
                                        to="/task"
                                        class="text-secondary-200 hover:text-white hover:bg-secondary-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                        active-class="bg-secondary-700 text-white"
                                    >
                                        <ClipboardDocumentListIconOutline class="h-6 w-6 shrink-0 text-white" />
                                        Tâches
                                    </NuxtLink>
                                </li>
                                <li v-if="isAdmin || isOps || isManager">
                                    <NuxtLink
                                        to="/documents"
                                        class="text-secondary-200 hover:text-white hover:bg-secondary-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                        active-class="bg-secondary-700 text-white"
                                    >
                                        <DocumentMagnifyingGlassIconOutline class="h-6 w-6 shrink-0 text-white" />
                                        Dataroom
                                    </NuxtLink>
                                </li>
                                <!--                                <li v-if="isAdmin || isOps || isManager"> -->
                                <!--                                    <NuxtLink -->
                                <!--                                        to="/acquisition" -->
                                <!--                                        class="text-secondary-200 hover:text-white hover:bg-secondary-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold" -->
                                <!--                                        active-class="bg-secondary-700 text-white" -->
                                <!--                                    > -->
                                <!--                                        <BanknotesIconOutline class="h-6 w-6 shrink-0 text-white" /> -->
                                <!--                                        M&A -->
                                <!--                                    </NuxtLink> -->
                                <!--                                </li> -->
                                <li v-if="isAdmin || isOps || isManager">
                                    <NuxtLink
                                        to="/fundercompany"
                                        class="text-secondary-200 hover:text-white hover:bg-secondary-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                        active-class="bg-secondary-700 text-white"
                                    >
                                        <CurrencyEuroIconOutline class="h-6 w-6 shrink-0 text-white" />
                                        Financeurs
                                    </NuxtLink>
                                </li>
                                <li v-if="isAdmin || isOps || isManager">
                                    <NuxtLink
                                        to="/bank"
                                        class="text-secondary-200 hover:text-white hover:bg-secondary-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                        active-class="bg-secondary-700 text-white"
                                    >
                                        <BuildingLibraryIconOutline class="h-6 w-6 shrink-0 text-white" />
                                        Banques
                                    </NuxtLink>
                                </li>
                                <li v-if="isAdmin || isOps || isManager">
                                    <NuxtLink
                                        to="/partnercompany"
                                        class="text-secondary-200 hover:text-white hover:bg-secondary-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                        active-class="bg-secondary-700 text-white"
                                    >
                                        <UsersIconOutline class="h-6 w-6 shrink-0 text-white" />
                                        Partenaires
                                    </NuxtLink>
                                </li>
                                <li v-if="isAdmin || isManager">
                                    <NuxtLink
                                        to="/broker"
                                        class="text-secondary-200 hover:text-white hover:bg-secondary-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                        active-class="bg-secondary-700 text-white"
                                    >
                                        <Icon
                                            name="fluent:handshake-20-regular"
                                            class="h-6 w-6 shrink-0 text-white"
                                        />
                                        Brokers
                                    </NuxtLink>
                                </li>
                                <li>
                                    <NuxtLink
                                        to="/timesheet"
                                        class="text-secondary-200 hover:text-white hover:bg-secondary-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                        active-class="bg-secondary-700 text-white"
                                    >
                                        <ClockIconOutline class="h-6 w-6 shrink-0 text-white" />
                                        Timesheet
                                    </NuxtLink>
                                </li>
                                <li v-if="isAdmin">
                                    <NuxtLink
                                        to="/settings"
                                        class="text-secondary-200 hover:text-white hover:bg-secondary-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                        active-class="bg-secondary-700 text-white"
                                    >
                                        <Cog6ToothIconOutline class="h-6 w-6 shrink-0 text-white" />
                                        Paramètres
                                    </NuxtLink>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>

        <!-- Static sidebar for desktop -->
        <div class="hidden lg:fixed lg:inset-y-0 lg:z-10 lg:flex lg:w-72 lg:flex-col">
            <!-- Sidebar component, swap this element with another sidebar if you like -->
            <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-secondary-600 px-6 pb-4">
                <div class="flex h-16 shrink-0 items-center">
                    <img
                        class="h-8 w-auto mx-auto"
                        src="/img/logo-beige.png"
                        alt="Kray Logo"
                    >
                </div>
                <ContactShoppingCart />
                <CurrentTimer />
                <nav
                    aria-label=""
                    class="flex flex-1 flex-col"
                >
                    <ul
                        role="list"
                        class="flex flex-1 flex-col gap-y-7"
                    >
                        <li>
                            <ul
                                role="list"
                                class="-mx-2 space-y-1"
                            >
                                <li>
                                    <NuxtLink
                                        to="/"
                                        class="text-secondary-200 hover:text-white hover:bg-secondary-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                        active-class="bg-secondary-700 text-white"
                                    >
                                        <HomeIconOutline class="h-6 w-6 shrink-0 text-white" />
                                        Dashboard
                                    </NuxtLink>
                                </li>
                                <li v-if="isAdmin || isManager">
                                    <NuxtLink
                                        to="/contact"
                                        class="text-secondary-200 hover:text-white hover:bg-secondary-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                        active-class="bg-secondary-700 text-white"
                                    >
                                        <IdentificationIconOutline class="h-6 w-6 shrink-0 text-white" />
                                        Contacts
                                    </NuxtLink>
                                </li>
                                <li v-if="isAdmin || isManager">
                                    <NuxtLink
                                        to="/client"
                                        class="text-secondary-200 hover:text-white hover:bg-secondary-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                        active-class="bg-secondary-700 text-white"
                                    >
                                        <UserGroupIconOutline class="h-6 w-6 shrink-0 text-white" />
                                        Clients
                                    </NuxtLink>
                                </li>
                                <li v-if="isAdmin || isOps || isManager">
                                    <NuxtLink
                                        to="/prospection"
                                        class="text-secondary-200 hover:text-white hover:bg-secondary-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                        active-class="bg-secondary-700 text-white"
                                    >
                                        <BeakerIconOutline class="h-6 w-6 shrink-0 text-white" />
                                        Prospection
                                    </NuxtLink>
                                </li>
                                <li v-if="isAdmin || isOps || isManager">
                                    <NuxtLink
                                        to="/task"
                                        class="text-secondary-200 hover:text-white hover:bg-secondary-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                        active-class="bg-secondary-700 text-white"
                                    >
                                        <ClipboardDocumentListIconOutline class="h-6 w-6 shrink-0 text-white" />
                                        Tâches
                                    </NuxtLink>
                                </li>
                                <li v-if="isAdmin || isOps || isManager">
                                    <NuxtLink
                                        to="/documents"
                                        class="text-secondary-200 hover:text-white hover:bg-secondary-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                        active-class="bg-secondary-700 text-white"
                                    >
                                        <DocumentMagnifyingGlassIconOutline class="h-6 w-6 shrink-0 text-white" />
                                        Dataroom
                                    </NuxtLink>
                                </li>
                                <li v-if="isAdmin || isOps || isManager">
                                    <NuxtLink
                                        to="/acquisition"
                                        class="text-secondary-200 hover:text-white hover:bg-secondary-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                        active-class="bg-secondary-700 text-white"
                                    >
                                        <BanknotesIconOutline class="h-6 w-6 shrink-0 text-white" />
                                        M&A
                                    </NuxtLink>
                                </li>
                                <li v-if="isAdmin || isOps || isManager">
                                    <NuxtLink
                                        to="/fundercompany"
                                        class="text-secondary-200 hover:text-white hover:bg-secondary-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                        active-class="bg-secondary-700 text-white"
                                    >
                                        <CurrencyEuroIconOutline class="h-6 w-6 shrink-0 text-white" />
                                        Financeurs
                                    </NuxtLink>
                                </li>
                                <li v-if="isAdmin || isOps || isManager">
                                    <NuxtLink
                                        to="/bank"
                                        class="text-secondary-200 hover:text-white hover:bg-secondary-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                        active-class="bg-secondary-700 text-white"
                                    >
                                        <BuildingLibraryIconOutline class="h-6 w-6 shrink-0 text-white" />
                                        Banques
                                    </NuxtLink>
                                </li>
                                <li v-if="isAdmin || isOps || isManager">
                                    <NuxtLink
                                        to="/partnercompany"
                                        class="text-secondary-200 hover:text-white hover:bg-secondary-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                        active-class="bg-secondary-700 text-white"
                                    >
                                        <UsersIconOutline class="h-6 w-6 shrink-0 text-white" />
                                        Partenaires
                                    </NuxtLink>
                                </li>
                                <li v-if="isAdmin || isManager">
                                    <NuxtLink
                                        to="/broker"
                                        class="text-secondary-200 hover:text-white hover:bg-secondary-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                        active-class="bg-secondary-700 text-white"
                                    >
                                        <Icon
                                            name="fluent:handshake-20-regular"
                                            class="h-6 w-6 shrink-0 text-white"
                                        />
                                        Brokers
                                    </NuxtLink>
                                </li>
                                <li>
                                    <NuxtLink
                                        to="/timesheet"
                                        class="text-secondary-200 hover:text-white hover:bg-secondary-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                        active-class="bg-secondary-700 text-white"
                                    >
                                        <ClockIconOutline class="h-6 w-6 shrink-0 text-white" />
                                        Timesheet
                                    </NuxtLink>
                                </li>
                                <li v-if="isAdmin">
                                    <NuxtLink
                                        to="/settings"
                                        class="text-secondary-200 hover:text-white hover:bg-secondary-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                        active-class="bg-secondary-700 text-white"
                                    >
                                        <Cog6ToothIconOutline class="h-6 w-6 shrink-0 text-white" />
                                        Paramètres
                                    </NuxtLink>
                                </li>
                            </ul>
                        </li>
                        <li v-if="isSales || isAdmin || isOps || isManager">
                            <div class="text-xs font-semibold leading-6 text-secondary-200">
                                Feed
                            </div>
                            <TeamActivities />
                        </li>
                    </ul>
                </nav>
            </div>
        </div>

        <div class="lg:pl-72">
            <div class="sticky top-0 z-10 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
                <button
                    type="button"
                    class="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                    @click="mobileMenuShow = true"
                >
                    <span class="sr-only">Open sidebar</span>
                    <svg
                        class="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                        />
                    </svg>
                </button>

                <!-- Separator -->
                <div
                    class="h-6 w-px bg-gray-900/10 lg:hidden"
                    aria-hidden="true"
                />

                <div class="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                    <GlobalSearch class="grow" />
                    <div class="flex items-center gap-x-4 lg:gap-x-6">
                        <!-- Profile dropdown -->
                        <UiDropdown class="relative">
                            <template #trigger>
                                <button
                                    v-if="user"
                                    id="user-menu-button"
                                    type="button"
                                    class="-m-1.5 flex items-center p-1.5"
                                    aria-expanded="false"
                                    aria-haspopup="true"
                                >
                                    <span class="sr-only">Open user menu</span>
                                    <UiAvatar
                                        class="h-8 w-8"
                                        :path="user.avatar"
                                    />
                                    <span class="hidden lg:flex lg:items-center">
                                        <span
                                            class="ml-4 text-sm font-semibold leading-6 text-gray-900"
                                            aria-hidden="true"
                                        >{{ user.full_name }}</span>
                                        <svg
                                            class="ml-2 h-5 w-5 text-gray-400"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                                clip-rule="evenodd"
                                            />
                                        </svg>
                                    </span>
                                </button>
                            </template>
                            <template #content>
                                <div
                                    class="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
                                    role="menu"
                                    aria-orientation="vertical"
                                    aria-labelledby="user-menu-button"
                                    tabindex="-1"
                                >
                                    <nuxt-link
                                        to="/logout"
                                        class="block px-3 py-1 text-sm leading-6 text-left w-full text-gray-900 hover:bg-gray-50"
                                        role="menuitem"
                                        tabindex="-1"
                                    >
                                        Déconnexion
                                    </nuxt-link>
                                </div>
                            </template>
                        </UiDropdown>
                    </div>
                </div>
            </div>

            <main class="py-10 relative bg-slate-50">
                <div class="px-4 sm:px-6 lg:px-8">
                    <slot />
                </div>
            </main>
        </div>
        <UiDialog />
        <UiToastWrapper />
    </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useAuthStore } from '~/stores/auth'
import roleChecker from '~/mixins/roleChecker'

export default {
    mixins: [roleChecker],

    data () {
        return {
            mobileMenuShow: false
        }
    },

    computed: {
        ...mapState(useAuthStore, ['user'])
    },

    watch: {
        $route () {
            this.mobileMenuShow = false
        }
    },

    methods: {
        ...mapActions(useAuthStore, ['logout'])
    }
}
</script>
